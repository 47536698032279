import React from "react";

const Contact = () => {
  const email = "growwfluence@gmail.com";

  const handleMailButtonClick = () => {
    const mailtoLink = `mailto:${email}`;
    window.location.href = mailtoLink;
  };

  return (
    <div className="contact-page-wrapper">
      <h1 className="primary-heading">Have a Question in Mind?</h1>
      <h1 className="primary-heading">Let Us Help You</h1>
      <div className="contact-form-container">
        <input type="text" placeholder={email} readOnly />
        <button className="secondary-button" onClick={handleMailButtonClick}>
          Mail Us
        </button>
      </div>
    </div>
  );
};

export default Contact;
