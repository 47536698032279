import React from "react";
import PickMeals from "../Assets/consult_1.webp";
import ChooseMeals from "../Assets/final.webp";
import DeliveryMeals from "../Assets/delivery_1.webp";

const Work = () => {
  const workInfoData = [
    {
      image: PickMeals,
      title: "Book a Free Consultation",
      text: "Our experts understand, guiding your goals",
    },
    {
      image: ChooseMeals,
      title: "Finalize Requirements at Minimum Cost",
      text: "We assure transparency, delivering premium results at minimal costs. ",
    },
    {
      image: DeliveryMeals,
      title: "Achieve Desired Results with Revisions",
      text: "We're committed to surpassing expectations.",
    },
  ];
  return (
    <div className="work-section-wrapper">
      <div className="work-section-top">
        <p className="primary-subheading">Our Approach</p>
        
        <p className="primary-text">
        At Growwfluence, our digital marketing agency, we follow a client-centric approach to ensure your success in the digital realm. Here's how we make it happen:
        </p>
      </div>
      <div className="work-section-bottom">
        {workInfoData.map((data) => (
          <div className="work-section-info" key={data.title}>
            <div className="info-boxes-img-container">
              <img className="info-boxes-img " src={data.image} alt="" height={"20px"}/>
            </div>
            <h2>{data.title}</h2>
            <p>{data.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Work;
