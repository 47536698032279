import React from "react";
import Logo from "../Assets/growwfluence_logo.png";
import { Link } from "react-scroll";


const Footer = () => {
  return (
    <div className="footer-wrapper">
      <div className="footer-section-one">
        <div className="footer-logo-container">
          <div className="nav-logo-container">
            <img src={Logo} alt="" />
          </div>
          <h2 className="footer-title-tag">Growwfluence</h2>
        </div>
      </div>
      <div className="footer-section-two">
        <div className="footer-section-columns">
          <Link to="home" spy={true} smooth={true} offset={0} duration={500} className="link-style">
            Home
          </Link>
          <Link to="work" spy={true} smooth={true} offset={-100} duration={500} className="link-style">
            Our Approach
          </Link>
          <Link to="services" spy={true} smooth={true} offset={-120} duration={500} className="link-style">
            Our Services
          </Link>
        </div>
        <div className="footer-section-columns">
          <span>+91-9131092896</span>
          <span>growwfluence@gmail.com</span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
