import React, { useState, useEffect } from "react";
import "./App.css";
import Home from "./Components/Home";
import About from "./Components/About";
import Work from "./Components/Work";
import Testimonial from "./Components/Testimonial";
import Contact from "./Components/Contact";
import Footer from "./Components/Footer";
import Navbar from "./Components/Navbar";
import Services from "./Components/Services";
import Faq from "./Components/Faq";
import BottomNavbar from "./Components/BottomNavbar";
import Preloader from "./Components/Preloader"; // Import the Preloader component

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate a delay for demonstration purposes
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 1500); // Adjust the duration as needed or remove this line if you want to remove the delay

    return () => clearTimeout(timeout);
  }, []);

  return (
    <div className="App">
      <div className={`preloader ${loading ? "" : "loaded"}`}>
        <Preloader color="#fe9e0d" />
      </div>

      <div className={`content ${loading ? "hidden" : ""}`}>
        <Navbar />
        <div id="home">
          <Home />
        </div>
        <div id="work">
          <Work />
        </div>
        <div id="services">
          <Services />
        </div>
        <div id="testimonials">
          <Testimonial />
        </div>
        <div id="about">
          <About />
        </div>
        <div id="faq">
          <Faq />
        </div>
        <Contact />
        <Footer />
        <BottomNavbar />
      </div>
    </div>
  );
}

export default App;
