import React from "react";
import "../css/Preloader.css"; // Import the new Preloader.css file

const Preloader = () => {
  return (
    <div className="preloader-container">
      <div className="loader">
        <div className="lds-ripple">
          <div></div>
          <div></div>
        </div>
      </div>
      <div className="text-container">Loading Business Brilliance...</div>
    </div>
  );
};

export default Preloader;
