/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import Logo from "../Assets/growwfluence_logo.png";
import { BsCart2 } from "react-icons/bs";
import { HiOutlineBars3 } from "react-icons/hi2";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import CommentRoundedIcon from "@mui/icons-material/CommentRounded";
import PhoneRoundedIcon from "@mui/icons-material/PhoneRounded";
import ShoppingCartRoundedIcon from "@mui/icons-material/ShoppingCartRounded";
import { Link } from "react-scroll";


const Navbar = () => {
  
  const [showWhiteNavbar, setShowWhiteNavbar] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const linkStyle = {
    cursor: 'pointer',
  };
  const menuOptions = [
    {
      text: "Home",
      to: "home",
      InfoIcon:""
    },
    {
      text: "Our Approach",
      to: "work",
    },
    {
      text: "Our Services",
      to: "services",
    },
    {
      text: "Testimonials",
      to: "testimonials",
    },
    {
      text: "About",
      to: "about",
    },
    {
      text: "FAQs",
      to: "faq",
    },
    
   
  ];



  const onItemClick = () => {
    setOpenMenu(false);
  };

  const redirectToWhatsApp = () => {
    const phoneNumber = '+919131092896'; // Replace with your WhatsApp number
    const message = encodeURIComponent('Hey Growwfluencers, I want a free consultation booking. May I know the available schedule ?');

    // Constructing the WhatsApp URL
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${message}`;

    // Opening the WhatsApp URL in a new window
    window.open(whatsappUrl, '_blank');
  };


  
  return (
    <nav >
      <div className="nav-logo-container">
      <img src={Logo} alt="" /> 
      
      </div>
      <div className="navbar-links-container">
      <Link to="home" spy={true} smooth={true} offset={-60} duration={500} style={linkStyle}>Home</Link>
      <Link to="work" spy={true} smooth={true} offset={-100} duration={500} style={linkStyle}>Our Approach</Link>
      <Link to="services" spy={true} smooth={true} offset={-120} duration={500} style={linkStyle}>Our Services</Link>
      <Link to="testimonials" spy={true} smooth={true} offset={-120} duration={500} style={linkStyle}>Testimonials</Link>
      <Link to="about" spy={true} smooth={true} offset={-100} duration={500} style={linkStyle}>About</Link>
      <Link to="faq" spy={true} smooth={true} offset={20} duration={500} style={linkStyle}>FAQs</Link>
      
        {/* <a href="">
        <BsCart2 className="navbar-cart-icon" />
        </a> */}
        <button className="primary-button" onClick={redirectToWhatsApp}>Contact Us</button>
      </div>
      <div className="navbar-menu-container">
        <HiOutlineBars3 onClick={() => setOpenMenu(true)} />
      </div>
      <Drawer open={openMenu} onClose={() => setOpenMenu(false)} anchor="right">
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={() => setOpenMenu(false)}
          onKeyDown={() => setOpenMenu(false)}
        >
          <List>
            {menuOptions.map((item) => (
              <ListItem key={item.text} disablePadding onClick={onItemClick}>
                <Link
                  to={item.to}
                  spy={true}
                  smooth={true}
                  offset={-100}
                  duration={500}
                  style={{
                    cursor: "pointer",
                    color: "inherit",
                    textDecoration: "none",
                    padding: "10px",
                    margin: "5px 10px",
                    textAlign: "center",
                  }}
                >
                  <ListItemText primary={item.text} />
                </Link>
              </ListItem>
            ))}
          </List>
          <Divider />
        </Box>
      </Drawer>

    </nav>
  );
};

export default Navbar;
