// faq.js

import React, { useState } from 'react';

import AboutBackgroundImage from "../Assets/faq.webp";
import '../css/faq.css'; // Import CSS file for styling

const FAQs = () => {
  const faqData = [
    
{
  question: 'How can I use short-form video content to promote my business?',
  answer: 'Short-form video platforms like TikTok and Instagram Reels offer a powerful and engaging way to reach new audiences. Keep it short and sweet, aim for videos under 60 seconds. Be creative and eye-catching with visuals, music, and text overlays. Tell a compelling story even in a short format, and use relevant hashtags for increased discoverability.'
  },
    {
      question: 'How long does it take to see results from SEO efforts?',
      answer: 'SEO results vary, but you can typically start seeing improvements in a few months. It\'s an ongoing process, and factors like competition, industry, and algorithm changes can impact the timeline.'
    },
    {
      question: 'What on-page SEO strategies do you implement?',
      answer: 'We focus on optimizing meta tags, headings, content, and images. Our team ensures a seamless user experience, mobile-friendliness, and fast page loading speed, contributing to higher search engine rankings.'
    },
    {
      question: 'How do you approach keyword research?',
      answer: 'Our keyword research involves identifying relevant and high-volume keywords for your business. We analyze user intent, competition, and industry trends to select keywords that align with your goals.'
    },
    // Add more FAQs as needed
  ];

  const [expandedIndex, setExpandedIndex] = useState(null);

  const handleToggle = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  return (

    <div className="about-section-container">
       <div className="faq-section-container">
      <p className="primary-subheading-faq ">FAQs</p>
      <h2 className="primary-heading-faq ">Frequently Asked Questions</h2>
      {faqData.map((faq, index) => (
        <div key={index} className="faq-item">
          <div className="faq-question" onClick={() => handleToggle(index)}>
            <h3>{faq.question}</h3>
            <span>{expandedIndex === index ? '▼' : '►'}</span>
          </div>
          {expandedIndex === index && <p className="faq-answer">{faq.answer}</p>}
        </div>
      ))}
    </div>
   
    <div className="faq-section-image-container">
      <img src={AboutBackgroundImage} className=" " alt="" />
    </div>
    </div>
   
  );
};

export default FAQs;
