import React from "react";
import ProfilePic from "../Assets/john-doe-image.webp";
import { AiFillStar } from "react-icons/ai";

const Testimonial = () => {
  return (
    <div className="work-section-wrapper">
      <div className="work-section-top">
        <p className="primary-subheading">Testimonial</p>
        <h1 className="primary-heading">What They Are Saying</h1>
        <p className="primary-text">
        Voices of Delight: Discover the Praise for Our Services!
        </p>
      </div>
      <div className="testimonial-section-bottom">
        <img src={ProfilePic} alt="" style={{ maxHeight: '150px' }}/>
        <p style={{fontWeight:'400'}}>
        Absolutely thrilled with the reel editing and shooting by Growwfluence! They turned my vision into a captivating masterpiece – seamless editing, incredible storytelling, and stunning visuals. Highly recommended!
        </p>
        <div className="testimonials-stars-container">
          <AiFillStar />
          <AiFillStar />
          <AiFillStar />
          <AiFillStar />
          <AiFillStar />
        </div>
        <h2>Rohit Kushwaha</h2>
        <h4 style={{fontWeight:'200'}}>Fitness Influencer</h4>
      </div>

      {/* <div className="testimonial-section-bottom">
        <img src={ProfilePic} alt="" style={{ maxHeight: '150px' }}/>
        <p style={{fontWeight:'400'}}>
        Absolutely thrilled with the reel editing and shooting by Growwfluence! They turned my vision into a captivating masterpiece – seamless editing, incredible storytelling, and stunning visuals. Highly recommended!
        </p>
        <div className="testimonials-stars-container">
          <AiFillStar />
          <AiFillStar />
          <AiFillStar />
          <AiFillStar />
          <AiFillStar />
        </div>
        <h2>Rohit Kushwaha</h2>
        <h4 style={{fontWeight:'200'}}>Fitness Influencer</h4>
      </div> */}
    </div>
  );
};

export default Testimonial;
