import React from "react";
import DigitalMarketing from "../Assets/digital_marketing.webp";
import AppDevelopment from "../Assets/mobile-development.webp";
import WebsiteDevelopment from "../Assets/web_dev.webp";
import graphicsDesigning from "../Assets/graphics.webp";
import VideoEditing from "../Assets/video-editor.webp";
import BusinessConsultation from "../Assets/business_consult.webp";
import BannerBackground from "../Assets/home-banner-background.webp";
import '../css/services.css'; // Import CSS file for styling
import Navbar from "./Navbar";
const Services = () => {
  const servicesData = [
    {
      image: DigitalMarketing,
      title: "Digital Marketing",
      text: "Boost your online presence with our strategic digital marketing solutions.",
    },
    {
      image: AppDevelopment,
      title: "App Development",
      text: "Crafting mobile applications tailored to your unique requirements.",
    },
    {
      image: WebsiteDevelopment,
      title: "Website Development",
      text: "Building user-friendly websites that make a lasting impression.",
    },
    {
      image: graphicsDesigning,
      title: "Graphics Designing",
      text: "Transform Your Brand with Visual Brilliance – Unleash the Design Magic!",
    },
    {
      image: VideoEditing,
      title: "Video Editing",
      text: "Transforming raw footage into captivating visual stories.",
    },
    {
      image: BusinessConsultation,
      title: "Business Consultation",
      text: "Guiding you towards strategic decisions for business growth.",
    },
  ];

  return (
    <div className="service-banner-container ">
    <div className="service-bannerImage-container">
      <img src={BannerBackground} alt="" />
    </div>
    <div className="work-section-wrapper">
      
     
      <div className="work-section-top">
        <p className="primary-subheading">Our Services</p>
        
        <p className="primary-text">
        Discover tailored services at Growwfluence to amplify your business. From strategic digital marketing to web development, unlock your full potential.
        </p>
      </div>
      <div className="service-bannerImage-container">
          <img src={BannerBackground} alt="" />
        </div>
      <div className="services-section-bottom">
        {servicesData.map((data, index) => (
          <div className="services-section-info" key={index}>
            <div className="info-boxes-img-container">
              <img  src={data.image} alt="" style={{ maxHeight: '100px' }}/>
            </div>
            <h2>{data.title}</h2>
            <p>{data.text}</p>
          </div>
        ))}
      </div>
    </div>
    </div>
    
  );
};

export default Services;
