// BottomNavbar.js

import React, { useEffect, useState } from "react";
import "../css/bottom_nav.css"; // Import the CSS file for styles
import { Link } from "react-scroll";
const linkStyle = {
    cursor: 'pointer',
  };
const BottomNavbar = () => {
  const [showNavbar, setShowNavbar] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 500) {
        setShowNavbar(true);
      } else {
        setShowNavbar(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div  className={`bottom-navbar ${showNavbar ? "visible" : "hidden"}`}>
      {/* Your bottom navbar content */}
      <div className="navbar-content">
      <Link to="home" spy={true} smooth={true} offset={-100} duration={500} style={linkStyle}>Home</Link>
      <Link to="work" spy={true} smooth={true} offset={-100} duration={500} style={linkStyle}>Our Approach</Link>
      <Link to="services" spy={true} smooth={true} offset={-120} duration={500} style={linkStyle}>Our Services</Link>
      <Link to="testimonials" spy={true} smooth={true} offset={-120} duration={500} style={linkStyle}>Testimonials</Link>
      <Link to="about" spy={true} smooth={true} offset={-100} duration={500} style={linkStyle}>About</Link>
      <Link to="faq" spy={true} smooth={true} offset={-60} duration={500} style={linkStyle}>FAQs</Link>
      </div>
    </div>
  );
};

export default BottomNavbar;
