import React, { useState } from "react";
import BannerBackground from "../Assets/home-banner-background.webp";
import BannerImage from "../Assets/home_poster2.webp";
import Navbar from "./Navbar";
import { FiArrowRight } from "react-icons/fi";
import { DownloadRounded } from "@mui/icons-material";
const Home = () => {
  const [isLoading, setIsLoading] = useState(false);

  const handleDownloadClick = () => {
    setIsLoading(true);

    // Simulate a 3-second delay for the download process
    setTimeout(() => {
       window.location.href = "https://drive.google.com/uc?export=download&id=1e41jbP2RuuEiqNpQQi83SciAHm6jLPGN";
      setIsLoading(false);
    }, 3000);
  };

  return (
    <div className="home-container">
      <div className="home-banner-container">
        <div className="home-bannerImage-container">
          <img src={BannerBackground} alt="" loading="lazy" />
        </div>
        <div className="home-text-section">
          <h1 className="primary-heading">Don't just exist online, thrive!</h1>
          <p className="primary-text">
            <h2 className="title-tag">Growwfluence</h2> crafts winning digital marketing and app or web dev solutions to help your business flourish.
          </p>
          <button className="secondary-button" onClick={handleDownloadClick} disabled={isLoading}>
            {isLoading ? (
              <>
                Waiting To Download... <div className="loading-spinner"></div>
              </>
            ) : (
              <>
                Download Our Portfolio <DownloadRounded />
              </>
            )}
          </button>
        </div>
        <div className="home-image-section">
          <img src={BannerImage} alt="" loading="lazy" />
        </div>
      </div>
    </div>
  );
};

export default Home;
