import React from "react";
import AboutBackground from "../Assets/about-background.webp";
import AboutBackgroundImage from "../Assets/about_poster.webp";
import { BsFillPlayCircleFill } from "react-icons/bs";
// Import the CSS file at the top of your React component file
import "../css/about.css";


const About = () => {

  const redirectToWhatsApp = () => {
    const phoneNumber = '+919131092896'; // Replace with your WhatsApp number
    const message = encodeURIComponent('Hey Growwfluencers, I want a free consultation booking. May I know the available schedule ?');

    // Constructing the WhatsApp URL
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${message}`;

    // Opening the WhatsApp URL in a new window
    window.open(whatsappUrl, '_blank');
  };


  return (
    <div className="about-section-container">
      <div className="about-background-image-container">
        <img src={AboutBackground} alt="" />
      </div>
      <div className="about-section-image-container">
        <img src={AboutBackgroundImage} className="about-section-image " alt="" />
      </div>
      <div className="about-section-text-container">
        <p className="primary-subheading">About</p>
        <h1 className="primary-heading">
        Empowering Digital Growth
        </h1>
        <p className="primary-text">
        We're Growwfluence, where pixels meet perfection. From nifty apps to dazzling websites, we spin digital wonders. Let's make your online story legendary  because boring is not our style!        </p>
      
        <div className="about-buttons-container">
          <button className="secondary-button" onClick={redirectToWhatsApp}>Book A Free Consultation</button>
          {/* <button className="watch-video-button">
            <BsFillPlayCircleFill /> Watch Video
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default About;
